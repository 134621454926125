import React from 'react';
import { Box, SimpleGrid, Button, Image, VStack, Text, useColorModeValue, useBreakpointValue, Flex, Heading } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faGamepad, faPlay } from '@fortawesome/free-solid-svg-icons';
import BitcoinPriceGame from './BitcoinPriceGame';
import BitcoinAdoptionSimulation from './BitcoinAdoptionSimulation';
import TriviaNight from './TriviaNight';
import MinerTycoon from './MinerTycoon';
import LightningMazeRunner from './RoutingMan';
import NonceHunter from './NonceHunter';
// import AmazingStackMachine from './AmazingStackMachine';
// import SatoshisScramble from './SatoshisScramble';

const Arcade = ({ bitcoinPrice, lastMempoolBlockData, glossaryData }) => {
  const [activeGame, setActiveGame] = React.useState(null);
  const hoverBg = useColorModeValue('gray.100', 'orange.500');
  const games = [
    { name: 'Mining Tycoon', component: MinerTycoon, image: 'https://timechainstats.com/img/poster-mining-tycoon.jpg' },
    { name: 'BanHammer!', component: BitcoinAdoptionSimulation, image: 'https://timechainstats.com/img/poster-banhammer.jpg' },
    { name: 'Nonce Hunt', component: NonceHunter, image: 'https://timechainstats.com/img/poster-nonce-hunt.jpg' },
    { name: '"Get Rekt" Simulator', component: BitcoinPriceGame, image: 'https://timechainstats.com/img/poster-get-rekt-simulator.jpg' },
    { name: 'Trivia Night', component: TriviaNight, image: 'https://timechainstats.com/img/poster-trivia-night.jpg' },
    { name: 'Node Runner', component: LightningMazeRunner, image: 'https://timechainstats.com/img/poster-node-runner.jpg' },
    // { name: 'Amazing Stack Machine', component: AmazingStackMachine, image: 'https://timechainstats.com/img/poster-amazing-stack-machine.jpg' },
    // { name: 'Satoshi\'s Scramble', component: SatoshisScramble, image: 'https://timechainstats.com/img/poster-satoshis-scramble.jpg' },
  ];

  // Responsive columns
  const columns = useBreakpointValue({ base: 2, sm: 2, md: 3, lg: 3, xl: 3 });

  return (
    <Box mb={10}>
      <Flex
        as="header"
        align="center"
        justify="space-between"
        wrap="wrap"
        padding={{ base: "1rem", md: "1.5rem" }}
        color="white"
      >
        <Flex align="center" mb={{ base: 2, md: 0 }}>
          <FontAwesomeIcon icon={faGamepad} size={{ base: "xs", sm: "2x", md: "2x" }} />
          <Heading 
            as="h1" 
            size={{ base: "md", md: "md" }} 
            letterSpacing={'tight'} 
            ml={2} 
            fontFamily="'Ubuntu', sans-serif" 
            fontWeight="bold"
          >
            Timechain Arcade
          </Heading>
        </Flex>
        <Button
          as="a"
          href="https://timechainstats.com"
          colorScheme="orange"
          size={{ base: "xs", sm: "xs", md: "xs" }}
          leftIcon={<FontAwesomeIcon icon={faDashboard} />}
          fontSize={{ base: "xs", sm: "xs", md: "xs" }}
          px={{ base: 2, sm: 3, md: 4 }}
        >
          Bitcoin Dashboard
        </Button>
      </Flex>
      <Box px={5} pt={0}>
        <SimpleGrid columns={columns} spacing={4}>
          {games.map((game, index) => (
            <VStack
              key={index}
              spacing={3}
              align="center"
              borderRadius="md"
              p={3}
              transition="all 0.3s"
              _hover={{
                bg: hoverBg,
                transform: 'scale(1.05)',
                boxShadow: '0 12px 20px rgba(0, 0, 0, 0.4)'
              }}
            >
              <Box
                as="button"
                onClick={() => setActiveGame(game.name)}
                width="100%"
                transition="all 0.3s"
                _hover={{ opacity: 0.8 }}
              >
                <Image
                  src={game.image}
                  alt={`${game.name} poster`}
                  borderRadius="md"
                  objectFit="cover"
                  width="100%"
                  height="auto"
                  aspectRatio={1}
                />
              </Box>
              <Text fontWeight="bold" fontSize="sm" textAlign="center">
                {game.name}
              </Text>
              <Button
                onClick={() => setActiveGame(game.name)}
                width="100%"
                colorScheme="orange"
                size="sm"
                leftIcon={<FontAwesomeIcon icon={faPlay} />}
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap={2}
              >
                Play Now
              </Button>
            </VStack>
          ))}
        </SimpleGrid>
        {activeGame && (
          React.createElement(
            games.find(game => game.name === activeGame).component,
            {
              isOpen: true,
              onClose: () => setActiveGame(null),
              bitcoinPrice,
              lastMempoolBlockData,
              glossaryData
            }
          )
        )}
      </Box>
    </Box>
  );
};

export default Arcade;