import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Text,
    VStack,
    HStack,
    Box,
    Progress,
    useToast,
    Table,
    Tbody,
    Thead,
    Th,
    Tr,
    Td,
    useColorMode,
    Switch,
    Flex,
    useBreakpointValue,
    Tooltip,
    SimpleGrid,
    Card,
    CardBody,
    Input,
    FormControl,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    useDisclosure
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faServer, faDesktop, faMemory, faWarehouse, faCoins, faBolt, faDigging, faInfoCircle, faRedo } from '@fortawesome/free-solid-svg-icons';
import { faBitcoin } from '@fortawesome/free-brands-svg-icons';
import AchievementBadge from './components/AchievementBadge';
import { BADGE_CONFIGS } from './config/badgeConfigs';
import LuxuryStore from './LuxuryStore';
import CollectedItems from './CollectedItems';
import GameInfoMinerTycoon from './GameInfoMinerTycoon';

const EventBanner = ({ currentEvent, eventTimer }) => {
    if (currentEvent) {
        return (
            <Alert status="warning" borderRadius={3}>
                <AlertIcon />
                <AlertTitle mr={2}>{currentEvent.name}</AlertTitle>
                <AlertDescription>{currentEvent.description} ({eventTimer}s remaining)</AlertDescription>
            </Alert>
        );
    }
    return (
        <Alert status="success" borderRadius={3}>
            <AlertIcon />
            <AlertTitle mr={2}>All Systems Operational</AlertTitle>
            <AlertDescription>Your mining operation is running smoothly.</AlertDescription>
        </Alert>
    );
};

const BAD_EVENT_PROBABILITY = 0.05;
const INITIAL_CREDITS = 2500;
const ENERGY_COST = 0.1;
const SELL_PRICE_RATIO = 0.3;
const ODD_JOBS_RATE = 7.21;
const INITIAL_ENERGY = 10000;
const BITCOIN_TO_CREDITS_RATE = 50000;
const POOL_MINING_REWARD_MULTIPLIER = 0.3;
const POOL_MINING_COST = 4;
const POOL_MINING_REWARD_RATE = 0.00000002;
const MAX_BITCOIN = 21000000;

const miners = [
    { id: 'cpu', name: 'CPU', icon: faDesktop, cost: 10, hashRate: 1, powerCost: 1 },
    { id: 'gpu', name: 'GPU', icon: faMemory, cost: 100, hashRate: 15, powerCost: 7 },
    { id: 'asic', name: 'ASIC', icon: faServer, cost: 1000, hashRate: 175, powerCost: 55 },
    { id: 'farm', name: 'Mining Farm', icon: faWarehouse, cost: 10000, hashRate: 2000, powerCost: 375 },
];

const badEvents = [
    {
        name: 'Power Outage',
        description: 'All miners are temporarily disabled',
        duration: 30,
        effect: (setInventory, setIsPowerOutage) => {
            setIsPowerOutage(true);
            setInventory(prev => {
                const newInventory = { ...prev };
                Object.keys(newInventory).forEach(minerId => {
                    newInventory[minerId] = { ...newInventory[minerId], active: false };
                });
                return newInventory;
            });
        }
    },
    {
        name: 'Fire!',
        description: 'You lost one random miner',
        duration: 30,
        effect: (setInventory) => {
            setInventory(prev => {
                const activeMiners = Object.keys(prev).filter(minerId => prev[minerId].count > 0);
                if (activeMiners.length > 0) {
                    const randomMiner = activeMiners[Math.floor(Math.random() * activeMiners.length)];
                    return {
                        ...prev,
                        [randomMiner]: {
                            ...prev[randomMiner],
                            count: Math.max(0, prev[randomMiner].count - 1)
                        }
                    };
                }
                return prev;
            });
        }
    },
    {
        name: 'Difficulty Adjustment',
        description: 'Mining difficulty increased by 30%',
        duration: 30,
        effect: (setMiningDifficulty) => {
            setMiningDifficulty(prev => prev * 1.3);
        }
    },
    {
        name: 'Broken Miners',
        description: 'One random miner group is disabled',
        duration: 30,
        effect: (setInventory, setBrokenMiners) => {
            setInventory(prev => {
                const activeMiners = Object.keys(prev).filter(minerId => prev[minerId].count > 0);
                if (activeMiners.length > 0) {
                    const randomMiner = activeMiners[Math.floor(Math.random() * activeMiners.length)];
                    setBrokenMiners(prevBroken => ({ ...prevBroken, [randomMiner]: true }));
                    return {
                        ...prev,
                        [randomMiner]: { ...prev[randomMiner], active: false }
                    };
                }
                return prev;
            });
        }
    },
];

const MinerTycoon = ({ isOpen, onClose }) => {
    const [credits, setCredits] = useState(() => {
        const storedCredits = localStorage.getItem('minerTycoonCredits');
        return storedCredits ? parseInt(storedCredits) : INITIAL_CREDITS;
    });
    const [bitcoinBalance, setBitcoinBalance] = useState(0);
    const [inventory, setInventory] = useState({});
    const [miningProgress, setMiningProgress] = useState(0);
    const [showBadge, setShowBadge] = useState(null);
    const [oddJobsActive, setOddJobsActive] = useState(false);
    const [energy, setEnergy] = useState(INITIAL_ENERGY);
    const [energyPurchaseAmount, setEnergyPurchaseAmount] = useState('');
    const [isPoolMining, setIsPoolMining] = useState(false);
    const [convertAmount, setConvertAmount] = useState('');
    const [miningDifficulty, setMiningDifficulty] = useState(1);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [eventTimer, setEventTimer] = useState(0);
    const [isActive, setIsActive] = useState(true);
    const [isLuxuryStoreOpen, setIsLuxuryStoreOpen] = useState(false);
    const [isPowerOutage, setIsPowerOutage] = useState(false);
    const [brokenMiners, setBrokenMiners] = useState({});
    const [collectedItems, setCollectedItems] = useState([]);
    const [showCollectedItems, setShowCollectedItems] = useState(false);
    const [totalBitcoinMined, setTotalBitcoinMined] = useState(19000000);

    const { isOpen: isResetConfirmOpen, onOpen: onResetConfirmOpen, onClose: onResetConfirmClose } = useDisclosure();

    const closeTimeoutRef = useRef(null);
    const miningIntervalRef = useRef(null);
    const eventIntervalRef = useRef(null);
    const toast = useToast();
    const { colorMode } = useColorMode();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const bgColor = colorMode === 'dark' ? '#121212' : '#ffffff';
    const color = colorMode === 'dark' ? 'white' : '#121212';
    const borderColor = colorMode === 'dark' ? '#ffffff' : '#121212';

    useEffect(() => {
        let eventInterval;
        if (isActive) {
            eventInterval = setInterval(() => {
                const hasMinersBought = Object.values(inventory).some(miner => miner.count > 0);

                if (!currentEvent && hasMinersBought && Math.random() < 0.15) {
                    const randomEvent = badEvents[Math.floor(Math.random() * badEvents.length)];
                    setCurrentEvent(randomEvent);
                    setEventTimer(randomEvent.duration);

                    // Apply the event effect
                    switch (randomEvent.name) {
                        case 'Difficulty Adjustment':
                            setMiningDifficulty(prev => prev * 1.3);
                            break;
                        case 'Power Outage':
                            setIsPowerOutage(true);
                            setInventory(prev => {
                                const newInventory = { ...prev };
                                Object.keys(newInventory).forEach(minerId => {
                                    newInventory[minerId] = { ...newInventory[minerId], active: false };
                                });
                                return newInventory;
                            });
                            break;
                        case 'Broken Miners':
                            const activeMiners = Object.keys(inventory).filter(minerId => inventory[minerId].count > 0);
                            if (activeMiners.length > 0) {
                                const randomMiner = activeMiners[Math.floor(Math.random() * activeMiners.length)];
                                setBrokenMiners(prev => ({ ...prev, [randomMiner]: true }));
                                setInventory(prev => ({
                                    ...prev,
                                    [randomMiner]: { ...prev[randomMiner], active: false }
                                }));
                            }
                            break;
                        case 'Fire!':
                            setInventory(prev => {
                                const activeMiners = Object.keys(prev).filter(minerId => prev[minerId].count > 0);
                                if (activeMiners.length > 0) {
                                    const randomMiner = activeMiners[Math.floor(Math.random() * activeMiners.length)];
                                    return {
                                        ...prev,
                                        [randomMiner]: {
                                            ...prev[randomMiner],
                                            count: Math.max(0, prev[randomMiner].count - 1)
                                        }
                                    };
                                }
                                return prev;
                            });
                            break;
                        default:
                            console.warn(`Unhandled event: ${randomEvent.name}`);
                    }
                }
            }, 5000);
        }
        return () => clearInterval(eventInterval);
    }, [isActive, currentEvent, inventory]);

    useEffect(() => {
        let eventTimer;
        if (currentEvent) {
            eventTimer = setInterval(() => {
                setEventTimer(prev => {
                    if (prev <= 1) {
                        clearInterval(eventTimer);
                        // Clear the event and its effects
                        setCurrentEvent(null);
                        if (currentEvent.name === 'Difficulty Adjustment') {
                            setMiningDifficulty(prev => prev / 1.3); // Reset mining difficulty
                        } else if (currentEvent.name === 'Power Outage') {
                            setIsPowerOutage(false);
                        } else if (currentEvent.name === 'Broken Miners') {
                            setBrokenMiners({});
                        }
                        // Reactivate all miners if they were deactivated by an event
                        setInventory(prev => {
                            const newInventory = { ...prev };
                            Object.keys(newInventory).forEach(minerId => {
                                if (newInventory[minerId].active === false && !oddJobsActive) {
                                    newInventory[minerId] = { ...newInventory[minerId], active: true };
                                }
                            });
                            return newInventory;
                        });
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(eventTimer);
    }, [currentEvent, oddJobsActive]);

    const resetGame = useCallback(() => {
        setCredits(INITIAL_CREDITS);
        setBitcoinBalance(0);
        setInventory({});
        setMiningProgress(0);
        setEnergy(INITIAL_ENERGY);
        setOddJobsActive(false);
        setIsPoolMining(false);
        setCurrentEvent(null);
        setEventTimer(0);
        setIsPowerOutage(false);
        setBrokenMiners({});
        setCollectedItems([]);
        setTotalBitcoinMined(19000000);
        setConvertAmount('');
        setMiningDifficulty(1);

        localStorage.removeItem('minerTycoonState');

        toast({
            duration: 2000,
            position: "top",
            variant: "solid",
            render: () => (
                <Box color='white' p={3} bg='blue.500' borderRadius={3}>
                    Game reset successfully!
                </Box>
            ),
            isClosable: true,
        });

        onResetConfirmClose();
    }, [toast, onResetConfirmClose]);

    useEffect(() => {
        localStorage.setItem('minerTycoonCredits', credits.toString());
    }, [credits]);

    const loadGameState = useCallback(() => {
        try {
            const savedState = JSON.parse(localStorage.getItem('minerTycoonState'));
            if (savedState) {
                setCredits(savedState.credits || INITIAL_CREDITS);
                setBitcoinBalance(savedState.bitcoinBalance || 0);
                setEnergy(savedState.energy || INITIAL_ENERGY);
                setInventory(savedState.inventory || {});
                setCollectedItems(savedState.collectedItems || []);
                setTotalBitcoinMined(savedState.totalBitcoinMined || 19000000);
            }
        } catch (error) {
            console.error('Error loading game state:', error);
            // If there's an error, we'll use the default values
        }
    }, []);

    const saveGameState = useCallback(() => {
        const gameState = {
            credits,
            bitcoinBalance,
            energy,
            inventory,
            collectedItems,
            totalBitcoinMined,
        };
        localStorage.setItem('minerTycoonState', JSON.stringify(gameState));
    }, [credits, bitcoinBalance, energy, inventory, collectedItems, totalBitcoinMined]);

    useEffect(() => {
        loadGameState();
    }, [loadGameState]);

    useEffect(() => {
        const saveInterval = setInterval(saveGameState, 5000); // Save every 5 seconds
        return () => clearInterval(saveInterval);
    }, [saveGameState]);

    const getTotalPowerCost = useCallback(() => {
        return Object.entries(inventory).reduce((total, [minerId, minerData]) => {
            if (minerData.active && !oddJobsActive) {
                const miner = miners.find(m => m.id === minerId);
                return total + (miner.powerCost * minerData.count);
            }
            return total;
        }, 0);
    }, [inventory, oddJobsActive]);

    const getActiveHashRate = useCallback(() => {
        return Object.entries(inventory).reduce((total, [minerId, minerData]) => {
            if (minerData.active && !oddJobsActive) {
                const miner = miners.find(m => m.id === minerId);
                return total + (miner.hashRate * minerData.count);
            }
            return total;
        }, 0);
    }, [inventory, oddJobsActive]);

    const mine = useCallback(() => {
        const activeHashRate = getActiveHashRate();
        const hasActiveMiners = activeHashRate > 0;

        let creditChange = 0;
        let bitcoinReward = 0;

        if (isPoolMining && !hasActiveMiners) {
            creditChange -= POOL_MINING_COST;
        }

        if (oddJobsActive) {
            creditChange += ODD_JOBS_RATE;
        }

        if (isPoolMining) {
            const reward = Math.min(
                hasActiveMiners
                    ? (activeHashRate / 100000) * POOL_MINING_REWARD_MULTIPLIER / miningDifficulty
                    : POOL_MINING_REWARD_RATE,
                MAX_BITCOIN - totalBitcoinMined
            );
            bitcoinReward += reward;
        }

        // Update credits, ensuring they don't go negative
        setCredits(prevCredits => {
            const newCredits = Math.max(0, prevCredits + creditChange);

            // If credits would become 0 and there are no active miners, disable pool mining
            if (newCredits === 0 && !hasActiveMiners && isPoolMining) {
                setIsPoolMining(false);
                toast({
                    duration: 4000,
                    position: "top",
                    variant: "solid",
                    render: () => (
                        <Box color='white' p={3} bg='orange.500' borderRadius={3}>
                            Out of credits! Pool mining has been disabled.
                        </Box>
                    ),
                    isClosable: true,
                });
            }

            return newCredits;
        });

        // Update Bitcoin balance
        if (bitcoinReward > 0) {
            setBitcoinBalance(prev => {
                const newBalance = prev + bitcoinReward;
                return isNaN(newBalance) ? prev : newBalance;
            });
            setTotalBitcoinMined(prev => Math.min(prev + bitcoinReward, MAX_BITCOIN));
        }

        // If there are no active miners and we're not fiat mining or pool mining, return early
        if (!hasActiveMiners && !oddJobsActive && !isPoolMining) {
            return;
        }

        // Handle energy costs and regular mining for active miners
        if (hasActiveMiners) {
            const energyCost = getTotalPowerCost() * ENERGY_COST;
            if (energy < energyCost) {
                toast({
                    duration: 4000,
                    position: "top",
                    variant: "solid",
                    render: () => (
                        <Box color='white' p={3} bg='red.500' borderRadius={3}>
                            Out of energy! All miners have been turned off.
                        </Box>
                    ),
                    isClosable: true,
                });
                setInventory(prev => {
                    const newInventory = { ...prev };
                    Object.keys(newInventory).forEach(minerId => {
                        newInventory[minerId] = { ...newInventory[minerId], active: false };
                    });
                    return newInventory;
                });
                return;
            }

            setEnergy(prevEnergy => {
                const newEnergy = Math.max(0, prevEnergy - energyCost);
                return isNaN(newEnergy) ? prevEnergy : newEnergy;
            });

            if (!isPoolMining) {
                // Solo mining logic
                setMiningProgress((prev) => {
                    const progressIncrement = activeHashRate / (10000 * miningDifficulty);
                    const newProgress = prev + progressIncrement;
                    if (newProgress >= 100 && totalBitcoinMined < MAX_BITCOIN) {
                        // 25% chance of finding a block
                        if (Math.random() < 0.25) {
                            const blocksFound = Math.floor(newProgress / 100);
                            setBitcoinBalance((prevBalance) => {
                                const reward = Math.min(blocksFound * 3.125, MAX_BITCOIN - totalBitcoinMined);
                                const newBalance = prevBalance + reward;
                                setTotalBitcoinMined(prev => {
                                    const newTotal = Math.min(prev + reward, MAX_BITCOIN);
                                    return isNaN(newTotal) ? prev : newTotal;
                                });
                                if (prevBalance === 0 && blocksFound > 0) {
                                    setShowBadge(BADGE_CONFIGS.minerTycoonFirstBlock);
                                }
                                if (newBalance >= 10 && prevBalance < 10) {
                                    setShowBadge(BADGE_CONFIGS.minerTycoonBitcoinMillionaire);
                                }

                                // Show a toast for successful block mining
                                toast({
                                    duration: 4000,
                                    position: "top",
                                    variant: "solid",
                                    render: () => (
                                        <Box color='white' p={3} bg='green.500' borderRadius={3}>
                                            Block mined! Received {reward.toFixed(8)} BTC
                                        </Box>
                                    ),
                                    isClosable: true,
                                });

                                return isNaN(newBalance) ? prevBalance : newBalance;
                            });
                        } else {
                            // Show a toast for unsuccessful block mining
                            toast({
                                duration: 4000,
                                position: "top",
                                variant: "solid",
                                render: () => (
                                    <Box color='white' p={3} bg='yellow.500' borderRadius={3}>
                                        Mining cycle completed, but no block found. Keep trying!
                                    </Box>
                                ),
                                isClosable: true,
                            });
                        }
                        return 0; // Reset progress after attempting to mine a block
                    }
                    return newProgress;
                });
            }
        }
    }, [energy, getTotalPowerCost, getActiveHashRate, oddJobsActive, isPoolMining, miningDifficulty, totalBitcoinMined, setShowBadge, toast, setIsPoolMining]);




    const hasMinersBought = useCallback(() => {
        return Object.values(inventory).some(miner => miner.count > 0);
    }, [inventory]);

    useEffect(() => {
        let eventInterval;
        if (isActive) {
            eventInterval = setInterval(() => {
                const hasMiners = hasMinersBought();

                if (!hasMiners && currentEvent) {
                    // Clear the event if there are no miners
                    setCurrentEvent(null);
                    setEventTimer(0);
                    setMiningDifficulty(1); // Reset mining difficulty
                    setIsPowerOutage(false);
                    setBrokenMiners({});
                } else if (hasMiners && !currentEvent && Math.random() < BAD_EVENT_PROBABILITY) {
                    const randomEvent = badEvents[Math.floor(Math.random() * badEvents.length)];
                    setCurrentEvent(randomEvent);
                    setEventTimer(randomEvent.duration);

                    // Apply the event effect
                    switch (randomEvent.name) {
                        case 'Difficulty Adjustment':
                            setMiningDifficulty(prev => prev * 1.3);
                            break;
                        case 'Power Outage':
                            setIsPowerOutage(true);
                            setInventory(prev => {
                                const newInventory = { ...prev };
                                Object.keys(newInventory).forEach(minerId => {
                                    newInventory[minerId] = { ...newInventory[minerId], active: false };
                                });
                                return newInventory;
                            });
                            break;
                        case 'Broken Miners':
                            const activeMiners = Object.keys(inventory).filter(minerId => inventory[minerId].count > 0);
                            if (activeMiners.length > 0) {
                                const randomMiner = activeMiners[Math.floor(Math.random() * activeMiners.length)];
                                setBrokenMiners(prev => ({ ...prev, [randomMiner]: true }));
                                setInventory(prev => ({
                                    ...prev,
                                    [randomMiner]: { ...prev[randomMiner], active: false }
                                }));
                            }
                            break;
                        case 'Fire!':
                            setInventory(prev => {
                                const activeMiners = Object.keys(prev).filter(minerId => prev[minerId].count > 0);
                                if (activeMiners.length > 0) {
                                    const randomMiner = activeMiners[Math.floor(Math.random() * activeMiners.length)];
                                    return {
                                        ...prev,
                                        [randomMiner]: {
                                            ...prev[randomMiner],
                                            count: Math.max(0, prev[randomMiner].count - 1)
                                        }
                                    };
                                }
                                return prev;
                            });
                            break;
                        default:
                            console.warn(`Unhandled event: ${randomEvent.name}`);
                            // If an unknown event occurs, we'll just clear it
                            setCurrentEvent(null);
                            setEventTimer(0);
                            break;
                    }
                }
            }, 5000);
        }
        return () => clearInterval(eventInterval);
    }, [isActive, currentEvent, inventory, hasMinersBought]);

    useEffect(() => {
        let eventTimer;
        if (currentEvent) {
            eventTimer = setInterval(() => {
                setEventTimer(prev => {
                    if (prev <= 1 || !hasMinersBought()) {
                        clearInterval(eventTimer);
                        setCurrentEvent(null);
                        if (currentEvent.name === 'Difficulty Adjustment') {
                            setMiningDifficulty(1); // Reset mining difficulty to base level
                        } else if (currentEvent.name === 'Power Outage') {
                            setIsPowerOutage(false);
                        } else if (currentEvent.name === 'Broken Miners') {
                            setBrokenMiners({});
                        }
                        setInventory(prev => {
                            const newInventory = { ...prev };
                            Object.keys(newInventory).forEach(minerId => {
                                if (newInventory[minerId].active === false && !oddJobsActive) {
                                    newInventory[minerId] = { ...newInventory[minerId], active: true };
                                }
                            });
                            return newInventory;
                        });
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        }
        return () => clearInterval(eventTimer);
    }, [currentEvent, oddJobsActive, hasMinersBought]);

    const stopGame = useCallback(() => {
        clearInterval(miningIntervalRef.current);
        clearInterval(eventIntervalRef.current);
        setIsActive(false);
        setOddJobsActive(false);
    }, []);

    const startGame = useCallback(() => {
        if (!isActive) {
            miningIntervalRef.current = setInterval(mine, 1000);
            eventIntervalRef.current = setInterval(() => {
                const hasMinersBought = Object.values(inventory).some(miner => miner.count > 0);
                if (!currentEvent && Math.random() < 0.15 && hasMinersBought) {
                    const randomEvent = badEvents[Math.floor(Math.random() * badEvents.length)];
                    setCurrentEvent(randomEvent);
                    setEventTimer(randomEvent.duration);
                    if (randomEvent.name === 'Power Outage') {
                        setIsPowerOutage(true);
                    } else if (randomEvent.name === 'Broken Miners') {
                        const activeMiners = Object.keys(inventory).filter(minerId => inventory[minerId].count > 0);
                        if (activeMiners.length > 0) {
                            const randomMiner = activeMiners[Math.floor(Math.random() * activeMiners.length)];
                            setBrokenMiners(prev => ({ ...prev, [randomMiner]: true }));
                        }
                    }
                    randomEvent.effect(setInventory, setMiningDifficulty);
                }
            }, 5000);
            setIsActive(true);
        }
    }, [isActive, mine, currentEvent, inventory]);

    useEffect(() => {
        if (isOpen) {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
                closeTimeoutRef.current = null;
            }
            startGame();
        } else {
            closeTimeoutRef.current = setTimeout(() => {
                stopGame();
            }, 3000);
        }

        return () => {
            if (closeTimeoutRef.current) {
                clearTimeout(closeTimeoutRef.current);
            }
        };
    }, [isOpen, startGame, stopGame]);

    useEffect(() => {
        if (isActive) {
            miningIntervalRef.current = setInterval(mine, 1000);
            return () => clearInterval(miningIntervalRef.current);
        }
    }, [isActive, mine]);

    useEffect(() => {
        if (currentEvent) {
            const eventInterval = setInterval(() => {
                setEventTimer(prev => {
                    if (prev <= 1) {
                        clearInterval(eventInterval);
                        setCurrentEvent(null);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(eventInterval);
        }
    }, [currentEvent]);

    const buyMiner = useCallback((miner) => {
        if (credits >= miner.cost) {
            setCredits(prevCredits => {
                const newCredits = prevCredits - miner.cost;
                setInventory(prevInventory => {
                    const newInventory = {
                        ...prevInventory,
                        [miner.id]: {
                            count: (prevInventory[miner.id]?.count || 0) + 1,
                            active: true
                        }
                    };

                    // Check for achievements
                    if (miner.id === 'asic' && !prevInventory['asic']) {
                        setShowBadge(BADGE_CONFIGS.minerTycoonAsicMiner);
                    }

                    if (miner.id === 'farm' && !prevInventory['farm']) {
                        setShowBadge(BADGE_CONFIGS.minerTycoonMiningFarm);
                    }

                    // Show toast message
                    toast({
                        duration: 2000,
                        position: "top",
                        variant: "solid",
                        render: () => (
                            <Box color='white' p={3} bg='green.500' borderRadius={3}>
                                Purchased {miner.name}
                            </Box>
                        ),
                        isClosable: true,
                    });

                    return newInventory;
                });

                return newCredits;
            });
        } else {
            toast({
                duration: 2000,
                position: "top",
                variant: "solid",
                render: () => (
                    <Box color='white' p={3} bg='red.500' borderRadius={3}>
                        Not enough credits to purchase {miner.name}
                    </Box>
                ),
                isClosable: true,
            });
        }
    }, [credits, setShowBadge, toast]);

    const sellMiner = useCallback((miner) => {
        if (inventory[miner.id]?.count > 0) {
            const sellPrice = Math.floor(miner.cost * SELL_PRICE_RATIO);
            setCredits(prev => prev + sellPrice);
            setInventory(prev => ({
                ...prev,
                [miner.id]: {
                    ...prev[miner.id],
                    count: prev[miner.id].count - 1
                }
            }));

            toast({
                duration: 2000,
                position: "top",
                variant: "solid",
                render: () => (
                    <Box color='white' p={3} bg='blue.500' borderRadius={3}>
                        Sold {miner.name} for {sellPrice} credits
                    </Box>
                ),
                isClosable: true,
            });
        } else {
            toast({
                duration: 2000,
                position: "top",
                variant: "solid",
                render: () => (
                    <Box color='white' p={3} bg='red.500' borderRadius={3}>
                        No {miner.name} available to sell
                    </Box>
                ),
                isClosable: true,
            });
        }
    }, [inventory, toast]);

    const toggleMiner = useCallback((minerId) => {
        if (!oddJobsActive && !isPowerOutage && !brokenMiners[minerId]) {
            setInventory(prev => {
                const minerData = prev[minerId];
                if (!minerData || minerData.count === 0) {
                    // If the miner doesn't exist or its count is 0, don't toggle
                    return prev;
                }
                return {
                    ...prev,
                    [minerId]: {
                        ...minerData,
                        active: !minerData.active
                    }
                };
            });
        }
    }, [oddJobsActive, isPowerOutage, brokenMiners]);

    useEffect(() => {
        if (currentEvent) {
            const eventInterval = setInterval(() => {
                setEventTimer(prev => {
                    if (prev <= 1) {
                        clearInterval(eventInterval);
                        setCurrentEvent(null);
                        if (currentEvent.name === 'Power Outage') {
                            setIsPowerOutage(false);
                        } else if (currentEvent.name === 'Broken Miners') {
                            setBrokenMiners({});
                        }
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
            return () => clearInterval(eventInterval);
        }
    }, [currentEvent]);

    const toggleOddJobs = () => {
        setOddJobsActive(prev => !prev);
        setInventory(prev => {
            const newInventory = { ...prev };
            Object.keys(newInventory).forEach(minerId => {
                if (!oddJobsActive) {
                    // Turning on odd jobs, disable all miners
                    newInventory[minerId] = { ...newInventory[minerId], active: false };
                } else {
                    // Turning off odd jobs, reactivate all miners that were previously active
                    newInventory[minerId] = { ...newInventory[minerId], active: true };
                }
            });
            return newInventory;
        });
    };

    const buyEnergy = (customAmount) => {
        const energyCost = 500; // Cost in credits for 1000 energy
        const amount = customAmount || 1; // Default to 1 pack if no custom amount
        const totalCost = energyCost * amount;

        if (credits >= totalCost) {
            setCredits(prev => prev - totalCost);
            setEnergy(prev => prev + (1000 * amount));
            toast({
                duration: 2000,
                position: "top",
                variant: "solid",
                render: () => (
                    <Box color='white' p={3} bg='green.500' borderRadius={3}>
                        Purchased {1000 * amount} energy for {totalCost} credits
                    </Box>
                ),
                isClosable: true,
            });
        } else {
            toast({
                duration: 2000,
                position: "top",
                variant: "solid",
                render: () => (
                    <Box color='white' p={3} bg='red.500' borderRadius={3}>
                        Not enough credits to purchase energy
                    </Box>
                ),
                isClosable: true,
            });
        }
    };

    const convertBitcoinToCredits = () => {
        const amount = parseFloat(convertAmount);
        if (isNaN(amount) || amount <= 0 || amount > bitcoinBalance) {
            toast({
                duration: 2000,
                position: "top",
                variant: "solid",
                render: () => (
                    <Box color='white' p={3} bg='red.500' borderRadius={3}>
                        Invalid conversion amount
                    </Box>
                ),
                isClosable: true,
            });
            return;
        }

        const creditsToAdd = Math.floor(amount * BITCOIN_TO_CREDITS_RATE);
        setBitcoinBalance(prev => prev - amount);
        setCredits(prev => prev + creditsToAdd);
        setConvertAmount('');

        toast({
            duration: 2000,
            position: "top",
            variant: "solid",
            render: () => (
                <Box color='white' p={3} bg='green.500' borderRadius={3}>
                    Converted {amount} BTC to {creditsToAdd} credits
                </Box>
            ),
            isClosable: true,
        });
    };

    const togglePoolMining = () => {
        setIsPoolMining(prev => !prev);
        toast({
            duration: 2000,
            position: "top",
            variant: "solid",
            render: () => (
                <Box color='white' p={3} bg='blue.500' borderRadius={3}>
                    {isPoolMining ? 'Switched to solo mining' : 'Joined mining pool'}
                </Box>
            ),
            isClosable: true,
        });
    };

    const MinerItem = useCallback(({ miner, isMobile }) => {
        const minerData = inventory[miner.id] || { count: 0, active: false };
        const isActive = minerData.count > 0 && minerData.active && !oddJobsActive;

        const content = (
            <>
                <HStack justify="space-between" mb={2}>
                    <Text><FontAwesomeIcon icon={miner.icon} /> {miner.name}</Text>
                    <Text>Owned: {minerData.count}</Text>
                </HStack>
                {isMobile ? (
                    <SimpleGrid columns={2} spacing={2} mb={2} fontSize="sm">
                        <Text>Cost: {miner.cost}</Text>
                        <Text>Sell: {Math.floor(miner.cost * SELL_PRICE_RATIO)}</Text>
                        <Text>Hash Rate: {miner.hashRate} H/s</Text>
                        <Text>Power: {miner.powerCost}/s</Text>
                    </SimpleGrid>
                ) : (
                    <SimpleGrid columns={2} spacing={2} mb={2}>
                        <Text>Cost: {miner.cost}</Text>
                        <Text>Sell: {Math.floor(miner.cost * SELL_PRICE_RATIO)}</Text>
                        <Text>Hash Rate: {miner.hashRate} H/s</Text>
                        <Text>Power: {miner.powerCost}/s</Text>
                    </SimpleGrid>
                )}
                <Flex justify="space-between" align="center" mb={2}>
                    <Text fontSize={isMobile ? "sm" : "sm"}>Active:</Text>
                    <Switch
                        isChecked={isActive}
                        onChange={() => toggleMiner(miner.id)}
                        isDisabled={minerData.count === 0 || oddJobsActive}
                        size="sm"
                    />
                </Flex>
                <HStack spacing={2}>
                    <Button
                        size="sm"
                        onClick={() => buyMiner(miner)}
                        colorScheme="green"
                        flex={1}
                        isDisabled={credits < miner.cost}
                    >
                        Buy
                    </Button>
                    <Button
                        size="sm"
                        onClick={() => sellMiner(miner)}
                        colorScheme="red"
                        isDisabled={minerData.count === 0}
                        flex={1}
                    >
                        Sell
                    </Button>
                </HStack>
            </>
        );

        return isMobile ? (
            <Card width="100%" bg={bgColor} color={color} borderColor={borderColor}>
                <CardBody>
                    {content}
                </CardBody>
            </Card>
        ) : (
            <Tr>
                <Td><FontAwesomeIcon icon={miner.icon} /> {miner.name}</Td>
                <Td isNumeric>{miner.cost}</Td>
                <Td isNumeric>{Math.floor(miner.cost * SELL_PRICE_RATIO)}</Td>
                <Td isNumeric>{miner.hashRate} H/s</Td>
                <Td isNumeric>{miner.powerCost}/s</Td>
                <Td isNumeric>{minerData.count}</Td>
                <Td>
                    <Switch
                        isChecked={isActive}
                        onChange={() => toggleMiner(miner.id)}
                        isDisabled={minerData.count === 0 || oddJobsActive}
                        size="sm"
                    />
                </Td>
                <Td>
                    <HStack spacing={2}>
                        <Button
                            size="xs"
                            onClick={() => buyMiner(miner)}
                            colorScheme="green"
                            isDisabled={credits < miner.cost}
                        >
                            Buy
                        </Button>
                        <Button
                            size="xs"
                            onClick={() => sellMiner(miner)}
                            colorScheme="red"
                            isDisabled={minerData.count === 0}
                        >
                            Sell
                        </Button>
                    </HStack>
                </Td>
            </Tr>
        );
    }, [inventory, oddJobsActive, toggleMiner, buyMiner, sellMiner, bgColor, color, borderColor, credits]);


    return (
        <Modal isOpen={isOpen} onClose={onClose} size={isMobile ? "full" : "6xl"}>
            <ModalOverlay backgroundColor="rgba(0, 0, 0, 0.95)" />

            <ModalContent bg={bgColor} color={color} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius={isMobile ? "0" : "6px"} overflow="hidden" pb={5}>
                <ModalHeader>
                    <Flex align="center" justify="space-between">
                        <Flex align="center">
                            <FontAwesomeIcon icon={faDigging} style={{ marginRight: '10px' }} />
                            <Text>Mining Tycoon</Text>
                        </Flex>

                    </Flex>
                    <Text fontSize="sm" fontWeight="normal" color="gray">
                        Build your Bitcoin mining empire!
                    </Text>

                </ModalHeader>

                <GameInfoMinerTycoon />
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4} align="stretch">
                        <SimpleGrid columns={[2, 2, 4]} spacing={2}>
                            <Text fontSize="sm" fontWeight="bold"><FontAwesomeIcon icon={faCoins} /> {credits.toFixed(2)} Credits</Text>
                            <Text fontSize="sm" fontWeight="bold"><FontAwesomeIcon icon={faBitcoin} /> {bitcoinBalance.toFixed(8)} BTC</Text>
                            <Text fontSize="sm" fontWeight="bold"><FontAwesomeIcon icon={faServer} /> {getActiveHashRate().toFixed(1)} H/s</Text>
                            <Text fontSize="sm" fontWeight="bold"><FontAwesomeIcon icon={faBolt} /> {energy.toFixed(0)} Energy</Text>
                        </SimpleGrid>


                        <Progress value={miningProgress} colorScheme="orange" size="md" />

                        {/* <Box>
                            <Text fontSize="sm">Total Bitcoin Mined: {totalBitcoinMined.toFixed(8)} BTC</Text>
                            <Progress value={(totalBitcoinMined / MAX_BITCOIN) * 100} colorScheme="blue" size="sm" />
                        </Box> */}

                        {isMobile ? (
                            <VStack spacing={3} align="stretch">
                                <Box>
                                    <EventBanner currentEvent={currentEvent} eventTimer={eventTimer} />
                                </Box>
                                <HStack spacing={2}>
                                    <Button
                                        onClick={() => buyEnergy()}
                                        colorScheme="orange"
                                        size="md"
                                        flex={1}
                                        isDisabled={energyPurchaseAmount !== ''}
                                    >
                                        Energy Pack (500 credits)
                                    </Button>
                                    <Input
                                        type="number"
                                        placeholder="# of packs"
                                        value={energyPurchaseAmount}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '' || (Number(value) > 0 && Number(value) <= Math.floor(credits / 500))) {
                                                setEnergyPurchaseAmount(value);
                                            }
                                        }}
                                        size="md"
                                        width="100px"
                                    />
                                    <Button
                                        onClick={() => {
                                            if (energyPurchaseAmount) {
                                                buyEnergy(Number(energyPurchaseAmount));
                                                setEnergyPurchaseAmount('');
                                            }
                                        }}
                                        colorScheme="orange"
                                        size="md"
                                        isDisabled={!energyPurchaseAmount}
                                    >
                                        Buy Packs
                                    </Button>
                                </HStack>
                            </VStack>
                        ) : (
                            <Flex justify="space-between" align="center">
                                <Box flex="1">
                                    <EventBanner currentEvent={currentEvent} eventTimer={eventTimer} />
                                </Box>
                                <HStack spacing={2} ml={4}>
                                    <Button
                                        onClick={() => buyEnergy()}
                                        colorScheme="orange"
                                        size="md"
                                        isDisabled={energyPurchaseAmount !== ''}
                                    >
                                        Energy Pack (500 credits)
                                    </Button>
                                    <Input
                                        type="number"
                                        placeholder="# of packs"
                                        value={energyPurchaseAmount}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value === '' || (Number(value) > 0 && Number(value) <= Math.floor(credits / 500))) {
                                                setEnergyPurchaseAmount(value);
                                            }
                                        }}
                                        size="md"
                                        width="100px"
                                    />
                                    <Button
                                        onClick={() => {
                                            if (energyPurchaseAmount) {
                                                buyEnergy(Number(energyPurchaseAmount));
                                                setEnergyPurchaseAmount('');
                                            }
                                        }}
                                        colorScheme="orange"
                                        size="md"
                                        isDisabled={!energyPurchaseAmount}
                                    >
                                        Buy Packs
                                    </Button>
                                </HStack>
                            </Flex>
                        )}


                        <Flex justify="space-between" align="center">
                            <HStack spacing={2} align="center">
                                <Text fontSize="sm">Fiat Mining (No Miners)</Text>
                                <Switch
                                    isChecked={oddJobsActive}
                                    onChange={toggleOddJobs}
                                    size="sm"
                                />
                                <Tooltip label="Earn credits slowly without using miners. All miners are turned off when Odd Jobs are active.">
                                    <span><FontAwesomeIcon icon={faInfoCircle} /></span>
                                </Tooltip>
                            </HStack>
                            <HStack spacing={2} align="center">
                                <Text fontSize="sm">Pool Mining</Text>
                                <Switch
                                    isChecked={isPoolMining}
                                    onChange={togglePoolMining}
                                    size="sm"
                                />
                                <Tooltip label="Join a mining pool for more frequent but smaller rewards.">
                                    <span><FontAwesomeIcon icon={faInfoCircle} /></span>
                                </Tooltip>
                            </HStack>
                        </Flex>

                        {isMobile ? (
                            <VStack spacing={4}>
                                {miners.map((miner) => (
                                    <MinerItem key={miner.id} miner={miner} isMobile={true} width="100%" />
                                ))}
                            </VStack>
                        ) : (
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th>Miner</Th>
                                        <Th isNumeric>Cost</Th>
                                        <Th isNumeric>Sell</Th>
                                        <Th isNumeric>Hash Rate</Th>
                                        <Th isNumeric>Power</Th>
                                        <Th isNumeric>Owned</Th>
                                        <Th>Active</Th>
                                        <Th>Actions</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {miners.map((miner) => (
                                        <MinerItem key={miner.id} miner={miner} isMobile={false} />
                                    ))}
                                </Tbody>
                            </Table>
                        )}

                        <Flex direction={['column', 'row']} width="100%" mt={4}>
                            <VStack spacing={2} width={['100%', 'auto']} mb={[4, 0]}>
                                <Button
                                    onClick={() => setIsLuxuryStoreOpen(true)}
                                    colorScheme="gray"
                                    size="md"
                                    width="100%"
                                >
                                    Spend Credits
                                </Button>
                                <Button
                                    onClick={() => setShowCollectedItems(true)}
                                    colorScheme="gray"
                                    size="md"
                                    width="100%"
                                >
                                    View Collection
                                </Button>

                            </VStack>

                            <Box width={['100%', '80%']} ml={[0, 4]}>

                                <FormControl>
                                    {isMobile ? (
                                        <VStack spacing={2} align="stretch">
                                            <Input
                                                type="number"
                                                value={convertAmount}
                                                onChange={(e) => setConvertAmount(e.target.value)}
                                                placeholder="Convert BTC to Credits"
                                                width="100%"
                                            />
                                            <Button
                                                onClick={convertBitcoinToCredits}
                                                colorScheme="gray"
                                                size="md"
                                                width="100%"
                                            >
                                                Convert
                                            </Button>
                                        </VStack>
                                    ) : (
                                        <Flex align="center" justify="flex-start">
                                            <Input
                                                type="number"
                                                value={convertAmount}
                                                onChange={(e) => setConvertAmount(e.target.value)}
                                                placeholder="Convert BTC to Credits"
                                                width="200px"
                                                mr={2}
                                            />
                                            <Button
                                                onClick={convertBitcoinToCredits}
                                                colorScheme="gray"
                                                size="md"
                                            >
                                                Convert
                                            </Button>

                                        </Flex>

                                    )}

                                    <Button
                                        leftIcon={<FontAwesomeIcon icon={faRedo} />}
                                        colorScheme="red"
                                        size="sm"
                                        onClick={onResetConfirmOpen}
                                        mt={3}
                                    >
                                        Reset Game
                                    </Button>

                                </FormControl>

                            </Box>
                        </Flex>

                        <Box fontSize="xs" color="gray.500">
                            <Text><strong>Disclaimer:</strong> Mining Tycoon is a fictional game designed solely for entertainment and educational purposes. It does not represent real-world Bitcoin mining operations or economics. The game mechanics, including mining difficulty, energy consumption, and Bitcoin generation, are greatly simplified. In-game "credits" and "Bitcoin" have no real-world value. This simulation is not financial advice; do not apply video game strategies to real-world decisions. Actual Bitcoin mining and investment carry significant risks not fully represented here. The game introduces basic concepts but should not be your sole information source. No real mining occurs, and your computer's resources are not used for mining.</Text>
                        </Box>
                    </VStack>

                    {showBadge && (
                        <AchievementBadge
                            onClose={() => setShowBadge(null)}
                            badgeConfig={showBadge}
                            downloadFileName={showBadge.downloadFileName}
                            shareText={showBadge.shareText}
                        />
                    )}
                </ModalBody>
            </ModalContent>
            <LuxuryStore
                isOpen={isLuxuryStoreOpen}
                onClose={() => setIsLuxuryStoreOpen(false)}
                credits={credits}
                setCredits={setCredits}
                addCollectedItem={(item) => {
                    setCollectedItems(prev => [...prev, item]);
                    toast({
                        duration: 4000,
                        position: "top",
                        variant: "solid",
                        render: () => (
                            <Box color='white' p={3} bg='green.500' borderRadius={3}>
                                You've added {item.name} to your collection!
                            </Box>
                        ),
                    });
                }}
            />
            <CollectedItems
                isOpen={showCollectedItems}
                onClose={() => setShowCollectedItems(false)}
                items={collectedItems}
            />
            <Modal isOpen={isResetConfirmOpen} onClose={onResetConfirmClose}>
                <ModalOverlay />
                <ModalContent mt={5} bg={bgColor} color={color} borderWidth="2px" borderStyle="solid" borderColor={borderColor} borderRadius={isMobile ? "0" : "6px"}>
                    <ModalHeader>Confirm Reset</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to reset the game? This action cannot be undone.
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="red" mr={3} onClick={resetGame}>
                            Reset
                        </Button>
                        <Button variant="ghost" onClick={onResetConfirmClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Modal>
    );
};

export default MinerTycoon;