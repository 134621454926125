import React, { useEffect, useState, useCallback } from 'react';
import { Text } from '@chakra-ui/react';
import './Preloader.css';
import { useColorMode } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';

const greetings = [
  'Analyzing stats...',
  'BTC is Bitcoin',
  'Building dashboard...',
  'Checking game theory...',
  'Climbing merkle trees...',
  'Counting blocks...',
  'Crunching the numbers...',
  'Deciphering "42"...',
  'Discarding soy lattes...',
  'Diving into the Mempool...',
  'Don\'t trust, verify',
  'Drawing lines...',
  'Exploring blocks...',
  'Finding block height...',
  'Finding support...',
  'Finding resistance...',
  'Flushing shitcoins...',
  'Ignoring the noise...',
  'Laughing at Faketoshi...',
  'Leaving Clown World...',
  'Lowering time preference...',
  'Loading datapoints...',
  'Magic Internet Money!',
  'Measuring block height...',
  'Receiving Bitcoin data...',
  'Remember your security!',
  'Reticulating splines...',
  'Reviewing the mempool...',
  'Running bitcoin...',
  'Scanning nodes...',
  'Setting whale traps...',
  'Stacking sats responsibly...',
  'Surfing the network...',
  'Surfing the threads...',
  'System refreshing...',
  'Take a quick break!',
  'Thanks, shadowy super coders!',
  'There is no second best!',
  'Tick tock, next block...',
  'Computing NGU...',
  'Upgrade your OpSec!',
];

const proTips = [
  <>Trouble with the app?<br />Reloading the page can help!</>,
  <>TimechainStats is a live dashboard.<br />Just load it and leave it running!</>,
  <>Mix-and-match the stats at the top.<br />Create your own dashboard!</>,
  <>Click the price at the top.<br />Choose your currency!</>,
  <>Do you prefer light mode?<br />Click the Sun icon at the top to change!</>,
  <>Download the TimechainStats PWA.<br />Install and skip the browser!</>,
  <>Bookmark this page.<br />New features added all the time!</>
];

export const Preloader = ({ formatNumber, data, bitcoinPrice, lastMempoolBlockData, fees, mempoolInfo, da }) => {
  const { colorMode } = useColorMode();
  const [greeting, setGreeting] = useState('');
  const [proTip, setProTip] = useState('');
  const [showReloadMessage, setShowReloadMessage] = useState(false);
  const { version } = require('../package.json');


  // const isDataValid = useCallback((data) => {
  //   console.log("Forcing isDataValid to return false for testing");
  //   return false;  // Temporary change for testing
  // }, []);


  const isDataValid = useCallback((data) => {
    const specificKeys = ['s08', 's11'];

    // Check for null or undefined data
    if (data === null || data === undefined) {
      return false;
    }

    // Check for null values in specific keys and their nested properties
    for (const key of specificKeys) {
      if (data[key] === null || data[key] === undefined) {
        return false;
      }

      if (key === 's08' && (data[key].blockchain?.Data?.hashrate === null || data[key].blockchain?.Data?.hashrate === undefined)) {
        return false;
      }

      if (key === 's11' && (data[key].lightning?.latest === null || data[key].lightning?.latest === undefined)) {
        return false;
      }
    }

    return true; // No null values found in specific keys
  }, []);



  useEffect(() => {
    console.log("Preloader dependencies state:", {
      hasValidData: isDataValid(data),
      hasBitcoinPrice: !!bitcoinPrice,
      hasLastBlock: !!lastMempoolBlockData,
      hasFees: !!fees,
      hasMempoolInfo: !!mempoolInfo,
      hasDa: !!da
    });

    if (
      isDataValid(data) &&
      bitcoinPrice &&
      lastMempoolBlockData &&
      fees &&
      mempoolInfo &&
      da
    ) {
      // Add a slightly longer delay to ensure all renders complete
      const timeoutId = setTimeout(() => {
        document.body.classList.add('loaded');
        console.log("Added loaded class to body");
      }, 500);

      return () => clearTimeout(timeoutId);
    } else {
      // Remove loaded class if data becomes invalid
      document.body.classList.remove('loaded');
      console.log("Removed loaded class from body");
    }
  }, [data, bitcoinPrice, lastMempoolBlockData, fees, mempoolInfo, da, isDataValid]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!document.body.classList.contains('loaded')) {
        console.log("Loading timeout reached");
        setShowReloadMessage(true);
      }
    }, 15000); // 15 seconds

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];
    setGreeting(randomGreeting);

    const randomProTip = proTips[Math.floor(Math.random() * proTips.length)];
    setProTip(randomProTip);
  }, []);

  const sectionColor = colorMode === 'dark' ? '#121212' : '#444';
  const textColor = colorMode === 'dark' ? '#fff' : '#fff';

  return (
    <div id="loader-wrapper">
      <div id="loader-bitcoin">
        <div className="svg-container">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            width="180px" height="180px" viewBox="100 100 400 400" xmlSpace="preserve">
            <filter id="dropshadow" height="130%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="5" />
              <feOffset dx="0" dy="0" result="offsetblur" />
              <feComposite in2="offsetblur" operator="in" />
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
            <path
              className="path"
              style={{ fill: '#000000', filter: 'url(#dropshadow)' }}
              d="M446.089,261.45c6.135-41.001-25.084-63.033-67.769-77.735l13.844-55.532l-33.801-8.424l-13.48,54.068
  c-8.896-2.217-18.015-4.304-27.091-6.371l13.568-54.429l-33.776-8.424l-13.861,55.521c-7.354-1.676-14.575-3.328-21.587-5.073
  l0.034-0.171l-46.617-11.64l-8.993,36.102c0,0,25.08,5.746,24.549,6.105c13.689,3.42,16.159,12.478,15.75,19.658L208.93,357.23
  c-1.675,4.158-5.925,10.401-15.494,8.031c0.338,0.485-24.579-6.134-24.579-6.134l-9.631,40.468l36.843,9.188
  c8.178,2.051,16.209,4.19,24.098,6.217l-13.978,56.17l33.764,8.424l13.852-55.571c9.235,2.499,18.186,4.813,26.948,6.995
  l-13.802,55.309l33.801,8.424l13.994-56.061c57.648,10.902,100.998,6.502,119.237-45.627c14.705-41.979-0.731-66.193-31.06-81.984
  C425.008,305.984,441.655,291.455,446.089,261.45z M368.859,369.754c-10.455,41.983-81.128,19.285-104.052,13.589l18.562-74.404
  C306.28,314.65,379.774,325.975,368.859,369.754z M379.302,260.846c-9.527,38.187-68.358,18.781-87.442,14.023l16.828-67.489
  C327.767,212.14,389.234,221.02,379.302,260.846z"/>
          </svg>
        </div>

        <div className="greeting" style={{ marginTop: '20px', color: textColor, fontSize: '24px' }}>
          {bitcoinPrice ? <b><span style={{ fontSize: '36px' }}>${formatNumber(bitcoinPrice, 2)}</span> <span style={{ fontSize: '14px' }}>USD</span></b> : greeting}
        </div>

        <div className="plz-wait pulsate">
          <FontAwesomeIcon icon={faHourglassHalf} style={{ fontSize: '18px' }} />
          &nbsp;&nbsp;Please wait<span className="pulsate">...</span>
        </div>

        <div>
          <Text
            fontSize={showReloadMessage ? "16px" : "14px"}
            color={showReloadMessage ? 'orange.500' : '#777'}
            mt={'50px'}
          >
            {showReloadMessage ? (
              <>
                Loading is taking longer than usual...
                <br /><br />
                Please try reloading the page.
                <br />
                Clearing the cache may also help.
              </>
            ) : (
              proTip
            )}
          </Text>
        </div>

        <div>
          <Text fontSize="10px" color='#777' mt={10}> Version {version} </Text>
        </div>

      </div>
      <div
        className="loader-section section-left"
        style={{ backgroundColor: sectionColor }}
      ></div>
      <div
        className="loader-section section-right"
        style={{ backgroundColor: sectionColor }}
      ></div>
    </div>
  );
};
